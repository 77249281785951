import React, { Component } from "react";

// CSS
import "../Shared.css";
import "../../App.css";
import "./ErrorBoundary.css";

type Props = {};
type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    let test = "";
  }

  reloadApp() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ backgroundColor: "#e8ecef", height: "100%", textAlign: "center", padding: 10 }}>
          <h1 style={{ color: "#343a40", marginTop: 30 }}>Something went wrong</h1>
          <h3 style={{ color: "#343a40" }}>There was an issue loading the page. Please try again.</h3>
          <div
            style={{
              cursor: "pointer",
              marginTop: 20,
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: 6,
              paddingBottom: 6,
              backgroundColor: "var(--cta)",
              width: 120,
              borderRadius: 4,
              fontWeight: 700,
              fontSize: 16,
              color: "white",
            }}
            onClick={this.reloadApp.bind(this)}
          >
            RELOAD
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
